@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

body {
       background-color: #101820FF;
       font-family: "Quicksand", sans-serif !important;
       font-weight: 300;
       line-height: 1.5;
}

.news-card-image {
       /* position: absolute; */
       height: 268px;
       width: 320px;
       border-radius: 4px;
       overflow: hidden;
       margin-left: 1px;
       background-size: cover;
       background-position: 50%;
       background-position-x: center;
}

.dark-text {
       color: #101820FF!important;
}

.theme-bg {
       background-color: #FEE715ff!important;
}

.sec-theme-bg {
       background-color: #101820FF!important;
}

.theme-text {
       color: #FEE715ff!important;
}

@media only screen 
and (min-width : 1183px)
and (max-width : 1382px) 
{
       .card-body-space {
              padding-left: 50px;
       }
}

@media only screen 
and (min-width : 437px)
and (max-width : 973px) 
{
       .image-pos {
              margin: 10px auto!important;
       }

       .news-card-image {
              width: 500px;
       }
}

@media only screen 
and (min-width : 437px)
and (max-width : 551px) 
{
       .image-pos {
              margin: 10px auto!important;
       }

       .news-card-image {
              width: 350px;
       }
}

/* loading */

.custom-loading ul {
       /* position: absolute;
       top: 30%;
       left: 50%; */
       transform: translate(-50%, 50%);
       margin: 0;
       padding: 0;
       display: flex
}
   
.custom-loading ul li {
       list-style: none;
       width: 20px;
       height: 20px;
       background: #Fff;
       margin: 0 5px;
       border-radius: 50%;
       animation: animate 1.4s linear infinite
}
   
@keyframes animate {
       0% {
           transform: translateY(0)
       }
   
       60% {
           transform: translateY(0)
       }
   
       80% {
           transform: translateY(-20px)
       }
   
       100% {
           transform: translateY(0px)
       }
}
   
.custom-loading ul li:nth-child(1) {
       animation-delay: -1.2s
}
   
.custom-loading ul li:nth-child(2) {
       animation-delay: -1.0s
}
   
.custom-loading ul li:nth-child(3) {
       animation-delay: -.8s
}
   
.custom-loading ul li:nth-child(4) {
       animation-delay: -0.6s
}